.review-modal {
  .ant-modal-content {
    border-radius: 5px;
  }

  .ant-modal-header {
    padding-top: 30px;
    border-bottom: 0;
  }

  .ant-modal-body {
    padding-top: 0;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }
}
