@import '../../variables.scss';


.ant-empty {
  .ant-empty-description {
    color: $gray;
  }

  .ant-empty-image {
    margin-top: px(35);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: auto;

    img {
      width: 100%;
      max-width: px(64);
      max-height: px(43);
      margin-bottom: px(20);
    }
  }
}