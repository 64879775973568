.pagination-base {
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  overflow: hidden;

  .ant-pagination-item {
    margin-right: 0;
    border-top: 0;
    border-bottom: 0;
  }

  .ant-pagination-prev {
    button.ant-pagination-item-link {
      border: 0;
      overflow: hidden;


      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .ant-pagination-next {
    button.ant-pagination-item-link {
      border: 0;
      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin: 0;
  }

  .ant-pagination-item.ant-pagination-item-active {
    background-color: #E2EFFC;
    border-color: transparent;
    border: 0;
  }
}