@import '../../../../../../variables.scss';

.videoClassroomItem {
  background-color: transparent;
  height: 100%;
  cursor: pointer;

  .videoPlayerWrapper {
    position: relative;
    transition: 0.5s;
    padding-top: 56.25%;
    /* Default */

    border-radius: 10px;

    &.video-disable {
      border: 3px solid #939393;
      background: rgba(229, 229, 229, 0.15);
    }

    .reactPlayer {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;

      .react-player__preview {
        border-radius: 10px;
      }
    }

    .blurPlayer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      border-radius: 8px;
    }

    .noteVideo {
      display: block;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .video-length {
      position: absolute;
      bottom: px(10);
      right: px(10);
      background: rgba(33, 33, 33, 0.5);
      border-radius: 2px;
      color: #ffffff;
      padding: 2px 4px;
      gap: 10px;
    }
  }

  .videoNameWrapper {
    display: flex;
    flex-direction: row;
    padding: px(15) 0 px(15) 0;

    .channelAvatar {
      .avatar {
        height: px(45);
        width: px(45);
        max-width: none;
      }
    }

    .infoWrapper {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 10px;
    }

    .channelName {
      font-size: 14px;
      color: #616161;
    }

    .videoName {
      font-size: px(16);
      text-align: justify;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #1a0303;
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  &.video-blue {
    .videoPlayerWrapper {
      border: 3px solid #1f91fa;
      background: rgba(31, 145, 250, 0.15);

    }

    .video-text-color {
      color: #1F91FA;
    }

    .video-organization-name {
      background-color: #1F91FA;
      color: #FFFFFF;
    }
  }

  &.video-green {
    .videoPlayerWrapper {
      border: 3px solid #34A853;
      background: rgba(52, 168, 83, 0.15);
      ;
    }

    .video-text-color {
      color: #34A853;
    }

    .video-organization-name {
      background-color: #34A853;
      color: #FFFFFF;
    }
  }

  &.video-yellow {
    .videoPlayerWrapper {
      border: 3px solid #F9A621;
      background: rgba(249, 166, 33, 0.15);
    }

    .video-text-color {
      color: #F9A621;
    }

    .video-organization-name {
      background-color: #F9A621;
      color: #FFFFFF;
    }
  }

  &.video-red {
    .videoPlayerWrapper {
      border: 3px solid #E13B19;
      background: rgba(225, 59, 25, 0.15);
    }

    .video-text-color {
      color: #E13B19;
    }

    .video-organization-name {
      background-color: #E13B19;
      color: #FFFFFF;
    }
  }

  &.video-dashboard {
    .videoPlayerWrapper {
      border: 3px solid #00CFD5;
      background: rgba(0, 207, 213, 0.15);
    }

    .video-text-color {
      color: #00CFD5;
    }

    .video-organization-name {
      background-color: #00CFD5;
      color: #FFFFFF;
    }
  }

  &.video-violet {
    .videoPlayerWrapper {
      border: 3px solid #6900C7;
      background: rgba(105, 0, 199, 0.15);
    }

    .video-text-color {
      color: #6900C7;
    }

    .video-organization-name {
      background-color: #6900C7;
      color: #FFFFFF;
    }
  }

  &.video-disable {
    .videoPlayerWrapper {
      border: 3px solid #939393;
      background: rgba(229, 229, 229, 0.15);
    }

    .video-text-color {
      color: #939393;
    }

    .video-organization-name {
      background-color: #939393;
      color: #FFFFFF;
    }
  }
}

// /* chrome and chromium based */
// .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
//   display: none;
// }

// .react-horizontal-scrolling-menu--scroll-container {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

@media screen and (max-width: 991px) {
  .videoClassroomItem {
    .videoPlayerWrapper {
      width: 100%;
    }

    .videoNameWrapper {
      .infoWrapper {
        text-align: left;
      }

      .videoName {
        padding: 5px;
        min-height: 30px;
        font-size: px(18);
        max-width: none;
        font-weight: 600;
      }
    }

    &:hover {
      transform: none;
    }
  }
}

.classroom-modal {
  .ant-collapse-item {
    div.ant-collapse-header {
      padding: 0;
    }
  }
}