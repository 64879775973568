$primaryColor: #006ed2;
$primaryBlack: #000000;
$primaryWhite: #ffffff;
$secondaryColor: #ff4d4f;
$bgGrey: #f8f8f8;
$blue: #1f91fa;
$strokeBlue: #1c62b9;
$gray: #606060;
$btnHover: #f0f0f0;

// px to rem
$base: 16;

@function px($inputPx: 0) {
  @return ($inputPx / $base * 1rem);
}
