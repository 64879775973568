.subscriptionsPlans-wrapper {
  .labelItem {
    /* 18px */
    font-size: 1.125rem;
    /* 28px */
    line-height: 1.75rem;

    text-align: left;
  }

  .valueItem {
    display: flex;
    cursor: pointer;
    align-items: center;
    text-align: right;
    gap: 0.25rem;
    font-weight: 300;
  }

  .item-selected {
    border: 1px solid #1f91fa;
    border-radius: 6px;
    box-shadow: 1px 2px 0 #eae9e9;
  }
}