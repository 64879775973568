@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

html {
  font-size: calc((var(--base) / 16) * 100%);
  --base: 16;
}

#root {
  overflow-x: hidden;
}

html,
body {
  touch-action: none;
}

/* width */
::-webkit-scrollbar {
  height: 6px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 6px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;

  @media screen and (max-width: 991px) {
    height: 4px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e5e5e5;
}