@import '../../../../../variables.scss';

.classroom {
  .classroom-header {
    background: linear-gradient(135deg, #ebdbff 0%, #cfe6ff 100%);
  }

  .item-data {
    filter: drop-shadow(-3px 3px 30px rgba(0, 0, 0, 0.09));
  }

  .tab-wrapper {
    border-bottom: 1px solid #e5e5e5;

    .type-item {
      padding: 10px 30px;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      color: #606060;
      font-weight: 600;

      &.type-active {
        border-bottom: 3px solid #030303;
        color: #030303;
      }
    }
  }

  .data-item {
    font-weight: 500;
    font-size: 18px;
    color: #606060;
  }
}

.classroom-modal {
  width: 80%;
  max-width: px(1000);

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-body {
    padding: 1.5rem;
  }
}
