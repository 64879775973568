.date-picker-base {
  &.ant-picker-range {
    border-radius: 10px;
    padding: px(15) 0;
  }

  .ant-picker-range-separator {
    padding-left: 0;
    padding-right: 25px;
  }
}