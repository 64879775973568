@import '../../variables.scss';

.searchField {
  // height: 100%;
  .ant-input-affix-wrapper,
  button.ant-input-search-button {
    height: px(45);
    border: 1px solid #cccccc;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .ant-input-group-addon {
    border-top-right-radius: 100px !important;
    border-bottom-right-radius: 100px !important;
    button.ant-input-search-button {
      border-top-right-radius: 100px !important;
      border-bottom-right-radius: 100px !important;
    }
  }

  .ant-input-wrapper.ant-input-group {
    span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      padding-left: px(16);
    }
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border: 1px solid #1c62b9;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #1c62b9;
  }

  .ant-input-affix-wrapper-lg {
    padding-left: px(8);
  }

  button.ant-input-search-button {
    width: px(80);
    background-color: $bgGrey;
  }

  .ant-input-clear-icon {
    display: flex;
  }

  button.ant-input-search-button {
    background-color: #f8f8f8;

    &:hover {
      background-color: #f0f0f0;
    }

    &:active {
      background-color: #e9e9e9;
    }
  }
}
