@import '../../variables.scss';

.hozScroll {
  position: relative;

  .hozScrollItemWrapper {
    margin-right: px(20);
    min-width: px(356);
    height: 100%;
  }
}

// /* chrome and chromium based */
// .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
//   display: none;
// }

// .react-horizontal-scrolling-menu--scroll-container {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

@media screen and (max-width: 991px) {
}

.scrollIcon {
  position: absolute;
  cursor: pointer;
  background: #f9f9f9;
  transition: all 200ms;
  top: px(100);
  width: px(50);
  height: px(50);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  img {
    // width: 100%;
    max-width: px(20);
    max-height: px(20);
  }

  &:hover {
    background: #e5e5e5;
  }

  &:active {
    background: #cccccc;
  }
}

.iconLeft {
  left: 0;
}

.iconRight {
  right: 0;
}
