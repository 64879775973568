.menuMobile {
  height: 100vh;
  max-height: 100vh !important;

  div.ant-modal-content {
    height: 100%;
    background: transparent;
    border: 0;

    .ant-modal-body {
      padding: 0;
      height: 100%;
    }
  }
}
