@import '../../variables.scss';

.pointMessage {
  transform: scale(0);
  transition-duration: 0.3s;

  &.visible {
    transform: scale(1);
    color: $secondaryColor;
  }

  &.success {
    color: $primaryColor;
  }
}

button.strokeBlueBtn {
  height: px(45);
  background-color: #f8f8f8;
  border: 1px solid $strokeBlue;
  color: $strokeBlue;
  font-size: px(18);
  line-height: px(121);
  padding: 0 px(20);
  /* identical to box height */

  display: flex;
  align-items: center;
  border-radius: 2px;

  span {
    margin-left: px(12);
  }

  &:hover {
    background-color: #def1ff;
  }
}

.mainBtn {
  background: $blue;
  border-radius: px(5);

  &:hover {
    background: #40a9ff;
  }

  &:active {
    background: #1c62b9;
  }
}

button.cancelBtn {
  background: rgba(255, 255, 255, 0.0001);
  /* Default */

  border: 1px solid #1f91fa;
  border-radius: 10px;
  color: #1f91fa;

  min-width: px(120);

  &:hover {
    color: #ffffff;
  }
}

button.dangerBtn {
  background-color: #e13b19;
  color: #ffffff;
  border: 1px solid #e13b19;
  border-radius: 10px;
}

button.terminalBtn {
  background: #ffffff;
  /* Default */

  border: 1px solid $secondaryColor;
  border-radius: 10px;
  color: $secondaryColor;

  min-width: px(120);

  // &:hover {
  //   color: #ffffff;
  // }
}