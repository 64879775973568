@import '../../variables.scss';

.baseModal {
  .baseModalTitle {
    text-align: center;
    color: $primaryColor;
  }

  .baseModalFooter {
    text-align: right;
    padding: 0 px(32) px(32);
  }

  .ant-modal-body {
    padding: px(32);
    padding-bottom: px(1);
  }

  .title {
    color: $blue;
    font-weight: 600;
    font-size: px(20);
    line-height: px(23);
    margin-bottom: px(24);
  }

  .submit-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
    margin-top: px(24);

    .cancelBtn {
      margin-right: px(20);
      background: rgba(255, 255, 255, 0.0001);
      border: 1px solid $blue;
      border-radius: 5px;
      color: $blue;
    }

    .ant-btn {
      min-width: px(140);
    }
  }

  &.thanks-modal,
  &.quiz-modal {
    .ant-modal-header {
      padding-top: 30px;
      border-bottom: 0;

      .ant-modal-title {
        color: #1f91fa;
      }
    }

    .ant-modal-body {
      padding: 0 24px;
    }

    .ant-modal-footer {
      padding: 15px 30px;
    }
  }
}

.reviewPointContainer {
  text-align: center;
  padding: 0 36px;

  .pointWrapper {
    text-align: center;

    .point {
      color: $secondaryColor;
      font-size: 16px;
      font-weight: 600;
    }

    .detailHeader {
      margin: auto;
    }
  }

  .methodContent {
    margin: 10px 0;
    text-align: center;
  }

  .ant-collapse-header {
    padding: 0;
  }

  .userCodeFormLayout {
    margin: 20px 0;

    .ant-form-item-label {
      text-align: center;
    }

    .ant-input {
      text-align: center;
    }
  }
}

.recordModal {
  text-align: center;
  margin: auto;
}

.userModal {
  .ant-modal-body {
    padding: px(32);
    padding-bottom: px(1);
  }
}

.modalCommon {
}

@media screen and (max-width: 991px) {
  .baseModal {
    max-height: 90vh;
  }

  .userModal {
    .ant-modal-body {
      padding: px(12);
    }
  }

  .ant-modal-wrap {
    display: flex;
    align-items: flex-end;

    &:has(.searchModal) {
      align-items: flex-start;
    }

    .searchModal {
      div.ant-modal-content {
        border-radius: 0;
      }
    }

    .ant-modal {
      padding-bottom: 0;
      margin: 0;
      max-width: 100%;
      width: 100% !important;

      .ant-modal-content {
        border: 1px solid #e5e5e5;
        border-radius: 15px 15px 0px 0px;
      }
    }
  }
}
