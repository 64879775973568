@import '../../../variables.scss';

div.searchModal {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;

  .ant-modal-body {
    padding: px(15) px(10);

    .searchWrapper {
      display: flex;

      .searchInput {
        width: 100%;
        background: #f1f1f1;
        border-radius: 9999px;

        .ant-input {
          background: #f1f1f1;
          color: #030303;
        }
      }
    }
  }
}
