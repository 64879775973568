.table-info {
  .ant-table {
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    overflow: hidden;

    .ant-table-thead>tr>th {
      background-color: #F0F0F0;
    }
  }

}