@import '../../../variables.scss';

div.purchaseVideoModal {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  max-width: px(890);

  .purchaseVideoWrapper {
    padding: px(10);

    .modalTitle {
      font-weight: 600;
      font-size: 20px;
      color: $blue;
      margin-bottom: px(40);
    }
  }

  .modalContext {
    .ant-table-content {
      background: $bgGrey;
      color: $primaryBlack;

      border-radius: 5px;
    }

    .ant-table-bordered {
      .ant-table-thead {
        tr {
          th.ant-table-cell {
            border-right: 1px solid#E5E5E5;
          }
        }
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        background-color: $btnHover;
        font-size: 15px;
        color: $primaryBlack;
      }
    }

    .moneyUsed {
      color: #939393;
      font-size: 13px;
    }

    .orderStatus {
      &.waiting {
        color: #939393;
      }

      &.delivering {
        color: #606060;
      }

      &.done {
        color: #1c62b9;
      }

      &.cancel {
        color: #d32f2f;
      }
    }

    .ant-empty {
      .ant-empty-description {
        color: $gray;
      }

      .ant-empty-image {
        margin-top: px(35);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        height: auto;

        img {
          width: 100%;
          max-width: px(64);
          max-height: px(43);
          margin-bottom: px(20);
        }
      }
    }
  }

  .closeBtn {
    border-radius: 5px;
    min-width: px(140);
    margin-bottom: px(10);
  }

  .ant-collapse-content {
    border-top: 0;
  }

  @media screen and (max-width: 991px) {
    .ant-modal-body {
      padding: px(10);

      overflow-y: auto;
      max-height: calc(90vh);

      .ant-collapse-item:not(.ant-collapse-item-active) {
        .anticon.anticon-right.ant-collapse-arrow {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  background: #ffffff;

  @media screen and (max-width: 991px) {
    height: 4px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e5e5e5;
}
