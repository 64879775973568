@import '../../../../../variables.scss';

.videoLessonsItem {
  background-color: transparent;
  height: 100%;
  cursor: pointer;

  .videoPlayerWrapper {
    position: relative;
    transition: 0.5s;
    padding-top: 56.25%;
    /* Default */

    border-radius: 10px;
    background: rgba(31, 145, 250, 0.15);

    &.video-disable {
      background: #939393;
    }

    .reactPlayer {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;

      .react-player__preview {
        border-radius: 10px;
      }
    }

    .blurPlayer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      border-radius: 8px;
    }

    .noteVideo {
      display: none;
    }

    &:hover {
      .noteVideo {
        display: block;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .video-length {
      position: absolute;
      bottom: px(10);
      right: px(10);
      background: rgba(33, 33, 33, 0.5);
      border-radius: 2px;
      color: #ffffff;
      padding: 2px 4px;
      gap: 10px;
      font-weight: 600;
    }
  }

  .videoNameWrapper {
    display: flex;
    flex-direction: row;
    padding: px(15) 0 px(15) 0;

    .channelAvatar {
      .avatar {
        height: px(45);
        width: px(45);
        max-width: none;
      }
    }

    .infoWrapper {
      display: flex;
      flex-direction: column;

      .lesson-info {
        .info-name {
          background-color: #1f91fa;
          color: #ffffff;
        }

        .info-repo {
          color: #34a853;
        }

        .info-point {
          color: #e13b19;
        }

        &.info-disable {
          .info-name {
            background-color: #939393;
            color: #ffffff;
          }

          .info-repo {
            color: #939393;
          }

          .info-point {
            color: #939393;
          }
        }
      }
    }

    .channelName {
      font-size: 14px;
      color: #616161;
    }

    .videoName {
      // padding-right: 15px;
      min-height: px(36);
      font-size: px(16);
      text-align: justify;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #1a0303;
    }
  }

  &:hover {
    transform: scale(1.05);
  }
}

// /* chrome and chromium based */
// .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
//   display: none;
// }

// .react-horizontal-scrolling-menu--scroll-container {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

@media screen and (max-width: 991px) {
  .videoClassroomItem {
    .videoPlayerWrapper {
      width: 100%;
    }

    .videoNameWrapper {
      .infoWrapper {
        text-align: left;
      }

      .videoName {
        padding: 5px;
        min-height: 30px;
        font-size: px(18);
        max-width: none;
        font-weight: 600;
      }
    }

    &:hover {
      transform: none;
    }
  }
}

.classroom-modal {
  .ant-collapse-item {
    div.ant-collapse-header {
      padding: 0;
    }
  }
}
