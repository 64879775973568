@import '../../../variables.scss';

.registration-form {
  background: #f9f9f9;
  padding: px(20);

  .registration-wrapper {
    background: #1c62b9;
    color: #ffffff;
    box-shadow: -4px 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: px(32);
  }

  .registration-success {
    box-shadow: -4px 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
  }

  @media screen and (max-width: 550px) {
    padding: px(10);

    .registration-wrapper {
      flex-direction: column;
      padding: px(10);

    }
  }
}