.fade-enter {
  opacity: 0;
  max-height: 0;
}

.fade-enter-active {
  opacity: 1;
  max-height: 200px;
  transition: opacity 300ms ease-in, max-height 300ms ease-in;
}

.fade-exit {
  opacity: 1;
  max-height: 200px;
}

.fade-exit-active {
  opacity: 0;
  max-height: 0;
  transition: opacity 300ms ease-in, max-height 300ms ease-in;
}

.fade {
  overflow: hidden;
}
